'use client'

import {useEffect} from "react";
import {useRouter} from "next/navigation";

const NotFound = () => {
    const router = useRouter();

    useEffect(() => {
        // router.push('/')
    }, [])

    return <div style={{minHeight: '50vh'}}>
        <h1 style={{margin: '200px auto', textAlign: 'center'}}>404 - stranica nije pronadjena</h1>
    </div>
}

export default NotFound
